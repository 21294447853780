import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import {Link} from "react-router-dom";
import {AppRoutes} from "../../store/constants";

export type MenuItemsProps = {
  isAdmin: boolean;
  venueListLabel: string;
  usersListLabel: string;
  logoutLabel: string;
}

export const MenuItems: React.FC<MenuItemsProps> =
  ({isAdmin, venueListLabel, usersListLabel, logoutLabel}) => {
    return (
      <div>
        <Link to={AppRoutes.venues()} style={{textDecoration: 'none', color: "#000000bf"}}>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary={venueListLabel}/>
          </ListItem>
        </Link>
        {isAdmin && <Link to={AppRoutes.users()} style={{textDecoration: 'none', color: "#000000bf"}}>
            <ListItem button>
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                <ListItemText primary={usersListLabel}/>
            </ListItem>
        </Link>}
        <Link to={AppRoutes.root()} style={{textDecoration: 'none', color: "#000000bf"}}>
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon/>
            </ListItemIcon>
            <ListItemText primary={logoutLabel}/>
          </ListItem>
        </Link>
      </div>
    );
  }

