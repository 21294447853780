import React from "react";
import {Venue} from "../../types";
import {Dashboard} from "./Dashboard";
import {useTranslation} from "react-i18next";
import MaterialTable from "material-table";
import {AddButton} from "../../components/AddButton";
import {ConfirmDialog} from "../../components/ConfirmDialog";


enum Dialog {
  deleteVenue = 'deleteVenue',
  addUser = 'addUser',
  editUser = 'editUser',
  none = 'none'
};

export const VenuePage: React.FC<Venue> = () => {
  const [t] = useTranslation();
  const [currentDialog, setCurrentDialog] = React.useState(Dialog.none);

  const handleDeleteOpen = React.useCallback(() => {
    setCurrentDialog(Dialog.deleteVenue);
  }, []);

  const handleDialogClose = React.useCallback(() => {
    setCurrentDialog(Dialog.none);
  }, []);

  const data: Venue[] = [
    {
      id: '',
      name: 'First venue',
      address: 'Berlin',
      description: 'First wall of berlin',
      imageUrl: '',
      coordinates: {latitude: 1, longitude: 2},
      isDeleted: false,
      exhibits: []
    }
  ];
  return (
    <Dashboard title={t("dashboard.venue")} isAdmin={true}>
      <ConfirmDialog
        title={t('dialog.deleteVenue.title')}
        description={t('dialog.deleteVenue.description')}
        positive={t('dialog.positive')}
        negative={t('dialog.negative')}
        open={currentDialog === Dialog.deleteVenue}
        onConfirm={handleDialogClose}
        onCancel={handleDialogClose}
      />
      <MaterialTable
        title=""
        columns={[
          {title: 'Image', field: 'imageUrl', render: rowData => <img src={rowData.imageUrl} alt='I am venue icon'/>},
          {title: 'Name', field: 'name'},
          {title: 'Address', field: 'address'},
          {title: 'Description', field: 'description'}
        ]}
        data={data}
        actions={[
          {
            icon: AddButton,
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: (event) => alert("You want to add a new row")
          },
          {
            icon: 'edit',
            tooltip: 'Edit User',
            onClick: (event, rowData) => alert("You saved " + rowData)
          },
          (rowData: any) => ({
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (event, rowData) => {handleDeleteOpen()},
            disabled: rowData.birthYear < 2000
          })
        ]}
        options={{
          actionsColumnIndex: -1
        }}
      />
    </Dashboard>
  )
}
