import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.scss';
import qs from 'query-string';
import * as serviceWorker from './serviceWorker';
import {Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ConnectedRouter} from 'connected-react-router';
import {createStore} from './store/store';
import {i18n} from './i18n';
import {SignInContainer} from "./containers/SignIn";
import {VenuePage} from "./containers/Dashboard/VenuePage";
import {UsersList} from "./containers/Dashboard/UsersList";
import {AppRoutes} from "./store/constants";
import {SignUpContainer} from "./containers/Signup";

const history = createBrowserHistory();
const store = createStore(history);
// eslint-disable-next-line no-restricted-globals
const {locale} = qs.parse(location.hash);

i18n(locale as string);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={AppRoutes.users()} component={UsersList}/>
        <Route path={AppRoutes.venues()} component={VenuePage}/>
        <Route path={AppRoutes.signUp()} component={SignUpContainer}/>
        <Route path={AppRoutes.root()} component={SignInContainer}/>
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
