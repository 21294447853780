import {createAction} from "redux-actions";
import {Credentials} from "../../types";

export const SIGN_IN = 'SIGN_IN';
export const signIn = createAction<Credentials>(SIGN_IN);
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const signInSuccess = createAction(SIGN_IN_SUCCESS);
export const SIGN_IN_PROGRESS = 'SIGN_IN_PROGRESS';
export const signInProgress = createAction(SIGN_IN_PROGRESS);
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
export const signInFailed = createAction(SIGN_IN_FAILED);

