import {UserInfo} from "firebase";
export type AppState = {
  router?: any;
  app?: any;
};

export type Credentials = {
  email: string;
  password: string;
}

export type SignUpInfo = Credentials & {
  firstName: string;
  lastName: string;
  repeatPassword: string;
}

export enum Role {
  user = 'user',
  admin = 'admin'
}

export type User = UserInfo & {
  role : Role;
  emailVerified: boolean;
  approved: boolean;
  isDeleted: boolean;
}

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type BaseInformation = Readonly<{
  id: string;
  name: string;
  address: string;
  description: string;
  imageUrl?: string;
  coordinates: Coordinates;
  isDeleted: boolean;
}>;

export type Venue = BaseInformation & {
  exhibits: ReadonlyArray<Exhibits>;
};

export type Exhibits = BaseInformation & Readonly<{
  venueId: string;
  description?: string;
  qrCode: string; // check if need base64 or url to the image.
  audioFileUrl: string;
}>;

export type AuditLog = ReadonlyArray<{
  id: string;
  email: string;
  displayName: string;
  change: string;
}>;

export type TableEntryUser = Pick<User, 'displayName' | 'uid' | 'role' | 'email' | 'approved'>;
