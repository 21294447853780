import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

/*
 * Create the i18next instance.
 *
 * For documentation, see: https://react.i18next.com/components/i18next-instance.html
 */
export const i18n = (locale?: string) => i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: locale,
    ns: ['app'],
    defaultNS: 'app',
    debug: true,
    interpolation: {
      prefix: '{',
      suffix: '}'
    },
    react: {
    },
    resources: {
      en: require('./en.json')
    }
  });
