import {ForkEffect, takeEvery, select, takeLatest} from 'redux-saga/effects';
import {signInSaga} from "../containers/SignIn/signInSaga";
import {SIGN_IN} from "../containers/SignIn/actions";


/**
 * Function to demonstrate logging on log.log using sagas
 * @param action
 */

function* logger(action: any) {
 console.log('Action:', action, 'state after:', yield select());
}

/*
We could add a wrapper to ensure type matching: (maybe also possible by adding type declaration

const takeLatestT = <PayLoad>(
  creator: (payload: PayLoad) => Action<PayLoad>, saga: (action: Action<PayLoad>) => void
) => takeLatest(creator, saga);
*/

export default function* rootSaga(): IterableIterator<ForkEffect> {
  yield takeLatest(SIGN_IN, signInSaga);
  yield takeEvery('*', logger);
}
