import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from "@material-ui/core";

import {useTranslation} from "react-i18next";
import {Role, TableEntryUser} from "../../types";

type UserDialogProps = {
  title: string;
  positive: string;
  negative: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  user?: TableEntryUser;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const UserDialog: React.FC<UserDialogProps> = ({positive, negative, title, onCancel, onConfirm, open, user}) => {
  const [t] = useTranslation();
  const d = user;
  const [values, setValues] = React.useState({
    name: d ? d.displayName : '',
    email: 'sa@t',
    role: Role.user
  });

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name as string]: event.target.value,
    }));
  };

  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Full Name"
          type="text"
          fullWidth
          value={values && values.name}
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          value={user && user.email}
        />
        <TextField
          autoFocus
          margin="dense"
          id="password"
          label="Password"
          type="password"
          fullWidth
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="user-role">Role</InputLabel>
          <Select
            value={user && user.role}
            onChange={handleChange}
            inputProps={{
              name: 'role',
              id: 'user-role',
            }}
          >
            <MenuItem value={Role.admin}>{t('dialog.addUser.roles.admin')}</MenuItem>
            <MenuItem value={Role.user}>{t('dialog.addUser.roles.user')}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          {positive}
        </Button>
        <Button onClick={onCancel} color="primary">
          {negative}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
