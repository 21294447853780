import React, {useCallback} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import crislogo from './logo-crisaccess.jpg'
import {Copyright} from "../../components/Copyright";
import {useTranslation} from "react-i18next";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {Credentials, SignUpInfo} from "../../types";
import {signUp} from "./actions";
import {AppRoutes} from "../../store/constants";

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type SignUpProps = {
  signUp: (credentials: Credentials) => void;
}

export const SignUp: React.FC<SignUpProps> = ({signUp}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [values, setValues] = React.useState<SignUpInfo>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    repeatPassword: ''
  });

  const handleChange = useCallback((name: keyof SignUpInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [name]: event.target.value});
  }, [values]);

  const handleLogin = useCallback(() => {
    signUp(values)
  }, [signUp, values]);


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <img src={crislogo} width={150} alt={t("login.logoAltText")}/>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              onChange={handleChange('firstName')}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              onChange={handleChange('lastName')}
              autoComplete="lname"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={handleChange('email')}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={handleChange('password')}
              autoComplete="current-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="repeatPassword"
                label="Repeat Password"
                type="password"
                id="repeat-password"
                onChange={handleChange('repeatPassword')}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleLogin}
        >
          Sign Up
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <Link href={AppRoutes.root()} variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={8}>
        <Copyright/>
      </Box>
    </Container>
  );
}

const mapDispatchToProps = (dispatch: Dispatch): SignUpProps => ({
  signUp: (credentials: Credentials) => dispatch(signUp(credentials))
});


export const SignUpContainer = connect(null, mapDispatchToProps)(SignUp);
