import {createAction} from "redux-actions";
import {Credentials} from "../../types";

export const SIGN_UP = 'SIGN_UP';
export const signUp = createAction<Credentials>(SIGN_UP);
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const signUpSuccess = createAction(SIGN_UP_SUCCESS);
export const SIGN_UP_PROGRESS = 'SIGN_UP_PROGRESS';
export const signUpProgress = createAction(SIGN_UP_PROGRESS);
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED';
export const signUpFailed = createAction(SIGN_UP_FAILED);

