import React, {useCallback} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import crislogo from './logo-crisaccess.jpg'
import {Copyright} from "../../components/Copyright";
import {useTranslation} from "react-i18next";
import {Dispatch} from "redux";
import {signIn} from "./actions";
import {connect} from "react-redux";
import {Credentials} from "../../types";
import {AppRoutes} from "../../store/constants";

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type SignInProps = {
  signIn: (credentials: Credentials) => void;
}

export const SignIn: React.FC<SignInProps> = ({signIn}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [values, setValues] = React.useState<Credentials>({
    email: '',
    password: ''
  });

  const handleChange = useCallback((name: keyof Credentials) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [name]: event.target.value});
  }, [values]);

  const handleLogin = useCallback(() => {
    signIn(values)
  }, [signIn, values]);


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <img src={crislogo} width={150} alt={t("login.logoAltText")}/>
        <Typography component="h1" variant="h5">
          {t("login.signIn")}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("login.emailLabel")}
          name="email"
          autoComplete="email"
          onChange={handleChange('email')}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t("login.passwordLabel")}
          type="password"
          id="password"
          onChange={handleChange('password')}
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary"/>}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleLogin}
        >
          {t("login.signIn")}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              {t("login.forgotPassword")}
            </Link>
          </Grid>
          <Grid item>
            <Link href={AppRoutes.signUp()} variant="body2">
              {t("login.signupAccount")}
            </Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={8}>
        <Copyright/>
      </Box>
    </Container>
  );
}

const mapDispatchToProps = (dispatch: Dispatch): SignInProps => ({
  signIn: (credentials: Credentials) => dispatch(signIn(credentials))
});


export const SignInContainer = connect(null, mapDispatchToProps)(SignIn);
