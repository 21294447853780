import * as Redux from 'redux';
import {AppState} from "../types";

export const initState: AppState = {
};

export type Store = Redux.Store<{}>;

export const AppRoutes = {
  venues: () => '/venues',
  users: () => '/users',
  signUp: () => '/signup',
  root: () => '/'
};
