import {History} from 'history';
import {combineReducers} from 'redux';
import * as Redux from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import {initState, Store} from './constants';
import rootSaga from './sagas';
import {connectRouter, routerMiddleware} from 'connected-react-router';
/**
 * allows usage of ReduxDevTools only in dev environment, not in production
 * @see https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm */ // tslint:disable-line:max-line-length
const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

/**
 * This handles a possibly unrecoverable state. Log and terminate application!
 * @param error
 */
const sagaErrorHandler = (error: Error) => {
  console.log(error);
};

export const rootReducerV1 = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: () => { return initState}
  });


type ActionTypes = any;
/**
 * Create the redux store
 */
export function createStore(history: History): Store {
  const sagaMiddleware = createSagaMiddleware({onError: sagaErrorHandler});
  const store = Redux.createStore(
    rootReducerV1(history),
    initState,
    composeEnhancers(
      Redux.applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history)
      )
    )
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
