import React from "react";
import {Role, TableEntryUser, User} from "../../types";
import {Dashboard} from "./Dashboard";
import {useTranslation} from "react-i18next";
import {AddButton} from "../../components/AddButton/";
import MaterialTable from "material-table";
import {ConfirmDialog} from "../../components/ConfirmDialog";
import {UserDialog} from "../../components/UserDialog";

enum Dialog {
  deleteUser = 'deleteUser',
  addUser = 'addUser',
  editUser = 'editUser',
  none = 'none'
};



export const UsersList: React.FC<User> = () => {
  const [t] = useTranslation();
  const [currentDialog, setCurrentDialog] = React.useState(Dialog.none);

  const [currentUser, setCurrentUser] = React.useState<TableEntryUser|undefined>(undefined);

  const handleDeleteOpen = React.useCallback((rowData) => {
    setCurrentDialog(Dialog.deleteUser);
  }, []);

  const handleAddOpen = React.useCallback(() => {
    setCurrentDialog(Dialog.addUser);
  }, []);

  const handleEditOpen = React.useCallback(({displayName, email, role, uid, approved}: TableEntryUser) => {
    setCurrentUser(oldValues => ({
      ...(oldValues && {oldValues}),
      displayName, email, role, uid, approved
    }));
    setCurrentDialog(Dialog.editUser);
  }, []);

  const handleDialogClose = React.useCallback(() => {
    setCurrentDialog(Dialog.none);
  }, []);

  const data: TableEntryUser[] = [
    {
      uid: '',
      displayName: 'Saurabh Gupta',
      role: Role.user,
      email: 'saurabh2590@gmail.com',
      approved: true
    }
  ];
  return (
    <Dashboard title={t("dashboard.users.title")} isAdmin={true}>
      <ConfirmDialog
        title={t('dialog.deleteUser.title')}
        description={t('dialog.deleteUser.description')}
        positive={t('dialog.positive')}
        negative={t('dialog.negative')}
        open={currentDialog === Dialog.deleteUser}
        onCancel={handleDialogClose}
        onConfirm={handleDialogClose}
      />
      <UserDialog
        title={currentDialog === Dialog.editUser ? t('dialog.editUser.title') : t('dialog.addUser.title')}
        positive={t('dialog.save')}
        negative={t('dialog.negative')}
        open={currentDialog === Dialog.addUser || currentDialog === Dialog.editUser}
        onCancel={handleDialogClose}
        onConfirm={handleDialogClose}
        user={currentDialog === Dialog.editUser ? currentUser : undefined}
      />
      <MaterialTable
        title=""
        columns={[
          {title: 'Name', field: 'displayName'},
          {title: 'Role', field: 'role'},
          {title: 'Email', field: 'email'},
          {title: 'Approved', field: 'approved', render: rowData => rowData.approved ? 'Yes' : 'No'}
        ]}
        data={data}
        actions={[
          {
            icon: () => <AddButton/>,
            tooltip: t('dashboard.users.tooltips.addUser'),
            isFreeAction: true,
            onClick: (event) => handleAddOpen()
          },
          {
            icon: 'edit',
            tooltip: t('dashboard.users.tooltips.editUser'),
            onClick: (event, rowData) => handleEditOpen(rowData as TableEntryUser)
          },
          (rowData: any) => ({
            icon: 'delete',
            tooltip: t('dashboard.users.tooltips.deleteUser'),
            onClick: (event, rowData) => handleDeleteOpen(rowData),
            disabled: rowData.birthYear < 2000
          })
        ]}
        options={{
          actionsColumnIndex: -1
        }}
      />
    </Dashboard>
  )
}
